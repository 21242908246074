<template>
	<div class="page-web">
		<Head />
		<div class="licai-section">
			<div class="licai-main">
				<div class="licai-head">
					<div class="licai-content">
						<h2>ALPHA <strong>{{$t('meun6')}}</strong></h2>
                    	<p>{{$t('earnDesc')}}</p>
						<!-- <div class="licai-title">{{$t('reTxt')}}</div>
						<div class="licai-desc">{{$t('reTxt2')}}</div> -->
					</div>
				</div>
				<div class="licai-body">
					<el-tabs v-model="activeName" @tab-click="handleClick">
						<el-tab-pane :label="$t('reTab')" name="first">
							<div class="li-panel">
								<div class="panel-head">
									<div class="panel-title">
										<div class="li-name">
											<span>
												{{$t('reTxt3')}}
											</span>
										</div>
										{{$t('reTxt4')}}
									</div>
								</div>
								<div class="panel-body">
									<div class="table-border">
										<el-table :data="tableData" style="width: 100%">
											<el-table-column prop="coinUnit" :label="$t('czTxt2')">
											</el-table-column>
											<el-table-column prop="rate" :label="$t('reth1')">
												<template slot-scope="scope">
													<div class="bi-text bi-green">
														{{scope.row.rate}}%
													</div>
												</template>
											</el-table-column>
											<el-table-column prop="duration" :label="$t('reth2')">
												<template slot-scope="scope">
													<span class="coin-day">{{scope.row.duration}}</span>
												</template>
											</el-table-column>
											<el-table-column :label="$t('reth3')">
												<template slot-scope="scope">
													<div class="bi-text">{{scope.row.num}} {{scope.row.coinUnit}}
													</div>
												</template>
											</el-table-column>
											<el-table-column align="center" width="140">
												<template slot-scope="scope">
													<div class="bi-btns">
														<el-button type="primary" @click="creatPlan(scope.row.id)">
															{{$t('rebtn')}}
														</el-button>
													</div>
												</template>
											</el-table-column>
										</el-table>
										<!-- <div class="table-foot">
											<el-button type="text">{{$t('reTxt8')}} <i
													class="el-icon-arrow-down"></i>
											</el-button>
										</div> -->
									</div>
								</div>
							</div>
						</el-tab-pane>
						<el-tab-pane :label="$t('reTab2')" name="second">
							<div class="li-panel">
								<div class="panel-head">
									<div class="panel-title">
										<div class="li-name">
											<span>
												{{$t('reTxt6')}}
											</span>
										</div>
										{{$t('reTxt7')}}
									</div>
									<div class="li-search">
										<el-input :placeholder="$t('placeholder')" v-model="unit" @input="search">
											<i slot="prefix" class="el-input__icon el-icon-search"></i>
										</el-input>
									</div>
								</div>
								<div class="panel-body">
									<div class="table-border">
										<el-table :data="tableData2" style="width: 100%">
											<el-table-column prop="coinUnit" :label="$t('reth4')" width="200">
											</el-table-column>
											<el-table-column prop="rate" width="600">
												<template slot="header">
													<div class="bi-th">
														{{$t('reth5')}}
														<el-button icon="el-icon-warning-outline"></el-button>
													</div>
												</template>
												<template slot-scope="scope">
													<div class="coin-select">
														<span class="rio"
															:class="[scope.row.rate.ty < 0 ?'sell':'buy']"
															v-if="scope.row.sort == 0">({{scope.row.rate.ty}})%</span>
														<span class="rio"
															:class="[scope.row.rate.oy < 0 ?'sell':'buy']"
															v-if="scope.row.sort == 1">({{scope.row.rate.oy}})%</span>
														<span class="rio"
															:class="[scope.row.rate.sm < 0 ?'sell':'buy']"
															v-if="scope.row.sort == 2">({{scope.row.rate.sm}})%</span>
														<span class="rio"
															:class="[scope.row.rate.tm < 0 ?'sell':'buy']"
															v-if="scope.row.sort == 3">({{scope.row.rate.tm}})%</span>
														<el-radio-group v-model="scope.row.sort">
															<el-radio-button :label="0">3{{$t('year')}}
															</el-radio-button>
															<el-radio-button :label="1">1{{$t('year')}}
															</el-radio-button>
															<el-radio-button :label="2">6{{$t('month')}}
															</el-radio-button>
															<el-radio-button :label="3">3{{$t('month')}}
															</el-radio-button>
														</el-radio-group>
													</div>
												</template>
											</el-table-column>
											<el-table-column prop="price" :label="$t('wth3')">
												<template slot-scope="scope">
													<div v-if="scope.row.price">
														{{scope.row.price}} {{scope.row.baseUnit}}
													</div>
												</template>
											</el-table-column>
											<el-table-column align="center" width="120">
												<template slot-scope="scope">
													<div class="bi-btns">
														<el-button type="primary" @click="creatPlan2(scope.row.id)">
															{{$t('rebtn')}}
														</el-button>
													</div>
												</template>
											</el-table-column>
										</el-table>
										<div class="table-foot">
											<!-- <el-button type="text">{{$t('reTxt8')}} <i
													class="el-icon-arrow-down"></i>
											</el-button> -->
										</div>
									</div>
								</div>
							</div>
						</el-tab-pane>
					</el-tabs>
					<div class="exte-box">
						<el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage"
							layout="prev, pager, next" :total="total" :background="true"></el-pagination>
					</div>
				</div>
				<el-dialog :visible.sync="dialogVisible" width="490px" custom-class="custom-dialog">
					<div slot="title" class="dialog-title"><b>{{detail.name}}</b></div>
					<div class="dialog-content">
						<div class="bi-panel">
							<div class="bi-hd">{{$t('wtth8')}}</div>
							<div class="bi-bd">
								<span v-if="detail.status == 1">{{$t('redongoing')}}</span>
								<span v-if="detail.status == 0">{{$t('Completed')}}</span>
							</div>
						</div>
						<div class="bi-panel">
							<div class="bi-hd">{{$t('reth3')}}</div>
							<div class="bi-bd">
								{{detail.num}} {{detail.coinUnit}}
							</div>
						</div>
						<div class="bi-panel">
							<div class="bi-hd">{{$t('uth3')}}</div>
							<div class="bi-bd">
								{{detail.rate}}%
							</div>
						</div>
						<div class="bi-panel">
							<div class="bi-hd">{{$t('mth5')}}</div>
							<div class="bi-bd">
								{{detail.duration}}
							</div>
						</div>
						<div class="bi-panel">
							<div class="bi-hd">{{$t('lctxt')}}</div>
							<div class="bi-bd">
								{{detail.coinUnit}}
							</div>
						</div>
						<div class="bi-panel">
							<div class="bi-hd">{{$t('lctxt2')}}</div>
							<div class="bi-bd">
								{{detail.coinUnit}}
							</div>
						</div>
						<div class="bi-row">
							<div class="bi-col">
								<div class="bi-label">{{$t('redTxt3')}}</div>
								<div class="bi-value">
									<el-input v-model="amount" @keyup.native="keyup"></el-input>
								</div>
								<div class="bi-balance">{{$t('redTxt4')}}
									<span v-if="wallet">{{wallet.balance || 0}} {{detail.coinUnit}}</span>
									<span v-else>0 {{detail.coinUnit}}</span>
								</div>
							</div>
						</div>
					</div>
					<div slot="footer" class="dialog-footer">
						<el-row :gutter="14">
							<el-col :span="12">
								<el-button @click="dialogVisible = false">{{$t('cancel')}}</el-button>
							</el-col>
							<el-col :span="12">
								<el-button type="primary" @click="lockedConfrim">{{$t('confirm')}}</el-button>
							</el-col>
						</el-row>
					</div>
				</el-dialog>
				<el-dialog :visible.sync="dialogVisible2" width="490px" custom-class="custom-dialog">
					<div slot="title" class="dialog-title">{{$t('redTxt')}} <b>{{detail2.coinUnit}}</b>
						{{$t('redTxt2')}}
					</div>
					<div class="dialog-content">
						<div class="bi-panel">
							<div class="bi-hd">{{$t('lctxt')}}</div>
							<div class="bi-bd">
								{{detail2.coinUnit}}
							</div>
						</div>
						<!-- <div class="bi-panel">
							<div class="bi-hd">{{$t('lctxt2')}}</div>
							<div class="bi-bd">
								{{detail2.baseUnit}}
							</div>
						</div> -->
						<!-- <div class="bi-panel">
							<div class="bi-hd">{{$t('lctxt3')}}</div>
							<div class="bi-bd">
								{{detail2.num}} {{detail2.baseUnit}}
							</div>
						</div> -->
						<div class="bi-row">
							<el-row :gutter="18">
								<el-col :span="18">
									<div class="bi-col">
										<div class="bi-label">{{$t('redTxt3')}}</div>
										<div class="bi-value">
											<el-input v-model="amount2" @keyup.native="keyup"></el-input>
										</div>
										<div class="bi-balance">{{$t('redTxt4')}}
											<span v-if="wallet2">{{wallet2.balance || 0}}
												{{detail2.baseUnit}}</span>
											<span v-else>0 {{detail2.baseUnit}}</span>
										</div>
									</div>
								</el-col>
								<el-col :span="6">
									<div class="bi-col">
										<div class="bi-label">{{$t('lctxt3')}}</div>
										<div class="bi-value" style="line-height: 40px;text-align: center;">
											{{detail2.num}} {{detail2.baseUnit}}
										</div>
									</div>
								</el-col>
							</el-row>

						</div>
						<div class="bi-panel">
							<div class="bi-hd">{{$t('redTxt6')}}</div>
							<div class="bi-bd">
								<div class="bi-radio">
									<el-radio-group v-model="cycle">
										<el-radio-button label="0">{{$t('redTxt7')}}</el-radio-button>
										<el-radio-button label="1">{{$t('redTxt8')}}</el-radio-button>
										<el-radio-button label="2">{{$t('redTxt9')}}</el-radio-button>
									</el-radio-group>
								</div>
							</div>
						</div>
						<div class="bi-panel">
							<div class="bi-hd">{{$t('redTxt6')}}</div>
							<div class="bi-bd">
								<div class="bi-date">
									<div class="bi-date__label">{{$t('redTxt10')}}</div>
									<div class="bi-date__inner">
										<el-input-number v-model="hour" style="width: 50px;"
											controls-position="right" :min="0" :step-strictly="true" :max="23">
										</el-input-number>
										<div class="bi-date__separator">:</div>
										<el-input-number v-model="minute" style="width: 50px;"
											controls-position="right" :min="0" :step-strictly="true" :max="59">
										</el-input-number>
									</div>
								</div>
								<div class="bi-satrtTime">{{$t('redTxt11')}}{{time}}
									{{hour}}:{{minute}}{{$t('redTxt12')}}
								</div>
							</div>
						</div>
					</div>
					<div slot="footer" class="dialog-footer">
						<el-row :gutter="14">
							<el-button type="primary" @click="autoInvestConfrim">{{$t('confirm')}}</el-button>
						</el-row>
					</div>
				</el-dialog>
			</div>
		</div>
		<Foot />
	</div>
</template>

<script>
	import {
		lockedList,
		lockedDetail,
		autoInvestList,
		lockedAdd,
		autoInvestDetail,
		autoInvestAdd
	} from '@/api/api/earn'
	import {
		assetWallet
	} from '@/api/api/user'
	// @ is an alias to /src
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	export default {
		name: 'Home',
		components: {
			Head,
			Foot
		},
		data() {
			return {
				dialogVisible: false,
				dialogVisible2: false,
				activeName: 'first',
				cycle: '0',
				hour: 4,
				minute: 45,
				tableData: [],
				tableData2: [],
				all: [],
				detail: {},
				detail2: {},
				unit: '',
				currentPage: 1,
				total: 10,
				pageSize: 10,
				isLogin: false,
				wallet: {},
				wallet2: {},
				amount: 0,
				amount2: 0,
				time: '',
				radio: '1',
			};
		},
		created() {
			this.isLogin = this.$store.state.isLogin
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				if (this.isLogin) {
					lockedList({
						pageNo: this.currentPage,
						pageSize: this.pageSize,
						unit: ''
					}).then(res => {
						if (res.code == 0) {
							var arr = []
							res.data.content.forEach(item => {
								item.rate = this.$math.format(
									this.$math.multiply(
										this.$math.bignumber(100), this.$math.bignumber(item.rate)
									)
								)
								return arr.push(item)
							})
							this.tableData = arr
						}
					})
				} else {
					// this.$message.error(this.$t('loginFirst'))
					// this.$router.push('/login')
				}
			},
			getList2() {
				autoInvestList({
					pageNo: this.currentPage,
					pageSize: this.pageSize,
					unit: ''
				}).then(res => {
					if (res.code == 0) {
						var arr = []
						res.data.content.forEach(item => {
							item.rate = JSON.parse(item.rate)
							return arr.push(item)
						})
						this.tableData2 = arr
						this.all = arr
					}
				})
			},
			handleClick(tab) {
				if (tab.name == "second") {
					this.getList2()
				}
			},
			handleCurrentChange(val) {
				this.currentPage = val
			},
			getWallet() {
				assetWallet({
					symbol: this.detail.coinUnit
				}).then(res => {
					if (res.code == 0) {
						this.wallet = res.data
					}
				})
			},
			getWallet2() {
				assetWallet({
					symbol: this.detail2.baseUnit
				}).then(res => {
					if (res.code == 0) {
						this.wallet2 = res.data
					}
				})
			},
			creatPlan(id) {
				lockedDetail({
					id: id
				}).then(res => {
					if (res.code == 0) {
						res.data.rate = this.$math.format(
							this.$math.multiply(
								this.$math.bignumber(100), this.$math.bignumber(res.data.rate)
							)
						)
						this.detail = res.data
						this.getWallet()
					}
				})
				this.dialogVisible = true
			},
			lockedConfrim() {
				// 输入检查
				if (!this.amount) {
					this.$notify.error({
						title: this.$t("czTxt9"),
						message: this.$t("minNumErr")
					});
					return;
				}
				// 大于0
				if (parseFloat(this.amount) == 0) {
					this.$notify.error({
						title: this.$t("czTxt9"),
						message: this.$t("minNumErr2")
					});
					return;
				}
				// 余额检查
				if (parseFloat(this.amount) > this.wallet.balance) {
					this.$notify.error({
						title: this.$t("czTxt9"),
						message: this.detail.coinUnit + " " + this.$t("balancenotenough")
					});
					return;
				}

				// 最小起兑量检查
				if (this.detail.num > 0 && this.amount < this.detail.num) {
					this.$notify.error({
						title: this.$t("czTxt9"),
						message: this.$t("minNumErr")
					});
					return;
				}

				lockedAdd({
					aid: this.detail.id,
					amount: this.amount
				}).then(res => {
					if (res.code == 0) {
						this.$message.success(this.$t('createSuccess'))
						this.dialogVisible = false
					} else {
						this.$message.error(res.message)
					}
				})


			},
			creatPlan2(id) {
				autoInvestDetail({
					id: id
				}).then(res => {
					if (res.code == 0) {
						this.detail2 = res.data
						this.getWallet2()
					}
				})
				const date = new Date()
				var year = date.getFullYear();
				var month = date.getMonth() + 1;
				var day = date.getDate();
				var hours = date.getHours();
				var minutes = date.getMinutes();
				if (month >= 1 && month <= 9) {
					month = "0" + month;
				}
				if (day >= 0 && day <= 9) {
					day = "0" + day;
				}
				this.hour = hours
				this.minute = minutes
				this.time = year + '-' + month + '-' + day
				this.dialogVisible2 = true
			},
			autoInvestConfrim() {
				// 输入检查
				if (!this.amount2) {
					this.$notify.error({
						title: this.$t("czTxt9"),
						message: this.$t("minNumErr")
					});
					return;
				}
				// 大于0
				if (parseFloat(this.amount2) == 0) {
					this.$notify.error({
						title: this.$t("czTxt9"),
						message: this.$t("minNumErr2")
					});
					return;
				}
				// 余额检查
				if (parseFloat(this.amount2) > this.wallet2.balance) {
					this.$notify.error({
						title: this.$t("czTxt9"),
						message: this.detail2.coinUnit + " " + this.$t("balancenotenough")
					});
					return;
				}

				// 最小起兑量检查
				if (this.detail2.num > 0 && this.amount2 < this.detail2.num) {
					this.$notify.error({
						title: this.$t("czTxt9"),
						message: this.$t("minNumErr")
					});
					return;
				}
				// 
				// if (this.time == "") {
				// 	this.$notify.error({
				// 		title: this.$t("czTxt9"),
				// 		message: this.$t("labStartPlaceholder")
				// 	});
				// 	return;
				// }
				var hours = this.hour
				var minutes = this.minute
				if (hours >= 1 && hours <= 9) {
					hours = "0" + hours;
				}
				if (minutes >= 0 && minutes <= 9) {
					minutes = "0" + minutes;
				}
				autoInvestAdd({
					aid: this.detail2.id,
					amount: this.amount2,
					cycle: this.cycle,
					startTime: hours + ':' + minutes + ':00'
				}).then(res => {
					if (res.code == 0) {
						this.$message.success(this.$t('createSuccess'))
						this.dialogVisible2 = false
					} else {
						this.$message.error(res.message)
					}
				})
			},
			search(value) {
				let keyWord = value
				this.tableData2 = this.all.filter(item => {
					return item.coinUnit.indexOf(keyWord) != -1 && item.coinUnit
						.localeCompare(keyWord) != -1
				})
			},
			keyup(e) {
				e.target.value = e.target.value.replace(/[^\d]/g, "")
			}
		}
	}
</script>
